.home-hero {

  text-align: center;
  color: #fff;
  padding-bottom: 30px;

  img {
    display: inline-block;
    margin-top: -60px;
    margin-bottom: -60px;
    width: 100%;
    max-width: 677px;
  }

  h1 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 38px;
    line-height: 1.15em;

    @media (max-width: 500px) {
      font-size: 30px;
      margin-top: 40px;
    }
  }

  .columns {
    .column {
      text-align: left;

      @media (max-width: 580px) {
        margin: 0;
        width: 100%;
      }
    }
  }

}
