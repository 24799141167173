// cta styles

.cta {
  @extend %montserrat;
  font-size: 22px;
  background: $main-blue;
  color: #fff;
  padding: 14px 0;

  span {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 4px;
  }
}
