
html, body {
  height: 100%;
}
.site-wrapper {
  min-height: 100%;
  margin: 0 auto -110px; /* the bottom margin is the negative value of the footer's height */
}

footer, .footer-push {
  height: 110px; /* '.push' must be the same height as 'footer' */
}

.footer {
  background: $main-blue;
  padding: 30px 0;
  overflow: hidden;
  color: #fff;
  bottom: 0;
  width: 100%;

  .menu {
    display: inline-block;
    float: none;
    margin: 0;
  }

  .copyright {
    font-size: 14px;
    position: relative;
    top: -3px;
  }
}

