

.bg-gradient {
  background: linear-gradient($main-blue, $main-red);
  min-height: 400px;
  color: #fff;
  
  h1, h2, h3 {
    color: #fff;
  }
}

.bg-red {
  background: $main-red;
  color: #fff;
}



