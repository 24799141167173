.home-suppliers {
  padding-bottom: 50px;
  text-align: center;
  padding-top: 10px;

  h2, h5 {
    color: #fff;
  }

  h5 {
    margin: 22px 0;
  }

  .supplier-list {
    @extend .clearfix;
  }

  .supplier-btns {
    margin-bottom: -10px;

    .btn {
      display: inline-block;
      margin-bottom: 10px;
    }

  }

  .supplier {
    width: 220px;
    height: 165px;
    overflow: hidden;
    display: inline-block;
    background: #fff;
    padding: 12px;

    box-shadow: 6px 6px 0 0 $main-blue;
    margin: 0 8px 12px 8px;
    color: $main-blue;
    font-size: 14px;

    .supplier-img {
      height: 70px;
      margin-bottom: 5px;
    }

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

  }
}
