.btn {

  &.btn-primary {
    background: $main-red;
    border-radius: 3px;
    border: 1px solid rgba($main-blue, 0.6);
    box-shadow: 2px 2px 0 0 rgba($main-red, 0.6);
    position: relative;
    transition: all 0.05s linear;
    text-transform: uppercase;
    font-weight: bold;
    @extend %montserrat;

    &:hover, &:active {
      transform: translate(1px, 1px);
      box-shadow: 1px 1px 0 0 rgba($main-red, 0.6);
    }
  }

  .bg-red & {
    &.btn-primary {
      background: $main-blue;
      border: 1px solid rgba($main-red, 0.6);
      box-shadow: 2px 2px 0 0 rgba($main-blue, 0.6);

      &:hover, &:active {
        box-shadow: 1px 1px 0 0 rgba($main-blue, 0.6);
      }
    }
  }
}
