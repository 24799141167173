
%muli {
  font-family:'Muli', sans-serif;;
}

%montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

body {
  @extend %muli;
  font-size: 16px;
  color: $main-blue;
}

h1, h2, h3, h4, h5 {
  @extend %montserrat;
  color: $main-blue;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 34px;
}


h5 {
  font-size: 14px;
  line-height: 1.3em;
}

p strong {
  @extend %montserrat;
}

.section-heading {
  margin-bottom: 34px;
}

p a {
  color: #3197C1;

  &:hover, &:active {
    color: darken(#3197C1, 5%);
    text-decoration: underline;
  }
}

