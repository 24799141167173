// nav styles

.header {
  height: 100px;
}

.logo {
  background: url('/images/spaceit-logo.svg') no-repeat;
  width: 184px;
  height: 38px;
  margin-top: 31px;
  float: left;
  vertical-align: middle;

  h1 {
    text-indent: -100%;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
  }

}

ul.menu {

  list-style: none;
  margin: 39px 0 0;
  padding: 0;
  float: right;

  li {
    float: left;
    margin: 0 10px;
    position: relative;

    &:after {
      content:'|';
      position: absolute;
      color: #fff;
      right: -12px;
      font-weight: bold;
    }

    &:last-child:after {
      display: none;
    }

    a {
      @extend %montserrat;
      color: #fff;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 510px) {
  .header {
    height: 140px;

    .logo {
      margin: 31px auto 16px;
      float: none;
    }

    nav {
      width:100%;
      text-align: center;
    }

    ul.menu {
      display: inline-block;
      float: none;
      margin-top:0;
    }
  }
}
