.steps {
  margin-top: 12px;

  .col-sm-6 {
    text-align: center;
  }
}

.step {

  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;

  img {
    width:100%;
    max-width: 300px;
    border: 4px solid $main-blue;
    box-shadow: 5px 5px 0 0 $main-blue;
  }

  h3 {
    text-align: left;
  }

  p {
    text-align: left;
  }

  .number {
    @extend %montserrat;
    font-size: 60px;
    text-align: center;
    line-height: 93px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    background: $main-red;
    border: 4px solid $main-blue;
    box-shadow: 5px 5px 0 0 $main-blue;
    color: #fff;
  }

  .col-sm-6:first-child & {
     .number {
      right: -50px;
      top: 30px;
    }
  }

  .col-sm-6:nth-child(2) & {

    .number {
      left: -50px;
      top: 95px;
    }
  }

}

.col-sm-12 {
  text-align: center;
  .step {

  }
}

.steps .col-sm-6:nth-child(2) {
  margin-top: 160px;
}
