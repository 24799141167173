.media {
  padding-bottom: 60px;
  h2 {
    margin-bottom: 30px;
  }
}


#download-list {
  .btn-link {
    margin-bottom: 10px;
  }
}

#video-gallery {
  margin-bottom: 30px;

  .video {
    display: block;
    position: relative;

    text-decoration: none;

    max-width: 400px;
    margin: 0 auto 25px;

    img {
      width: 100%;
      border: 4px solid $main-blue;
      box-shadow: 6px 6px 0 0 $main-blue;
      position: relative;
      display: block;
      transition: all 0.05s linear;
    }

    &:hover, &:active {
      img {
        box-shadow: 5px 5px 0 0 $main-blue;
        transform: translate(1px, 1px);
      }
    }

    .video-img {
      position: relative;
      //&:after {
      //  content: ' ';
      //  display: block;
      //  z-index:10;
      //  background: #fff;
      //  position: absolute;
      //  width:10px;
      //  height: 10px;
      //  top: 0;
      //  left: 0;
      //}
    }

    h5 {
      margin-top: 14px;
      color: #fff;
      text-align: center;

    }
  }
}
